<template>
  <v-main>
    <v-card class="mx-auto my-14" max-width="374" color="#fdf9f9">
      <v-row>
        <v-col class="text-center">
          <v-img class="ma-auto" src="../assets/logo.png" width="150"></v-img>
        </v-col>
      </v-row>
      <v-form v-on:submit.prevent="onSubmit" ref="formRef" class="text-center">
        <v-card-text>
          <h2 class="primary--text">Recupere sua senha</h2>
          <v-row dense class="mt-2">
            <v-col>
              <p class="primary--text">Digite seu email e enviaremos um e-mail de recuperação de senha.</p>
              <v-text-field outlined type="email" v-model="email" placeholder="E-mail" dense />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-0">
          <v-container>
            <v-btn :loading="isLoading('post')" type="submit" color="primary" class="white--text" block elevation="0">
              Enviar Email
            </v-btn>
          </v-container>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-main>
</template>

<script>
import UtilsFunc from '../service/utilsFunc';
import UtilsService from '../service/utilsService';
import axios from 'axios'

export default {
  name: 'ForgotPassword',
  data: () => UtilsFunc.withCRUDUtils({
    email: '',
  }),
  methods: {
    async onSubmit() {
      const isValid = this.$refs.formRef.validate()
      if (!isValid) return
      const LOADING_NAME = 'post'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await axios.get(`${UtilsService.urlApi()}/contas/request-email/?email=${this.email}`)
        if (!data.email)
          return this.$toast.error('Email não cadastrado ou desativo no sistema.')
        await axios.post(`${UtilsService.urlApi()}/contas/request-reset-email/`, {
          username: data.username,
          email: this.email
        })
        this.$toast.success(`Email de recuperação enviado para ${this.email}`)
        this.email = '';
      } catch (error) {
        this.$toast.error('Email não cadastrado ou desativo no sistema.')
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    }
  }
}
</script>
